import React, { SetStateAction, useContext, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { LuCheck, LuCheckCircle2, LuEye, LuLoader, LuMinusCircle, LuRotateCw, LuTrash, LuXCircle, } from "react-icons/lu";
import { IoCloseCircle, IoCheckmarkCircle } from "react-icons/io5";
import { toastError, toastSuccess, toastWarning } from "../Toast/Toast";
import IAgentService from "../../Services/AgentServices/IAgentService";
import IUser from "../../Services/UserManagement/IUserManagementService";
import UserContext from "../../Context/UserContext/UserContext";
import { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";
import { IAgentFile } from "../../Services/TemplateService/ITemplateService";
import { ISAgentFile, getFileName, getFileSize, getTrainingStatus } from "../Utils/Utils";
import AgentService from "../../Services/AgentServices/AgentService";
import { ThreeDotLoader } from "../Loader/Loader";
import { useLocation, useSearchParams } from "react-router-dom";
import TemplateService from "../../Services/TemplateService/TemplateService";

interface Props {
    UpdateAssistant: IAgentService
    SetUpdateAssistant: React.Dispatch<SetStateAction<IAgentService>>
    SetDeletedFileIds: React.Dispatch<SetStateAction<string[]>>
    DisableSubmitButton: any
    TemplateId?:string
}


const UploadFiles: React.FC<Props> = ({ UpdateAssistant, SetUpdateAssistant, SetDeletedFileIds, DisableSubmitButton, TemplateId }) => {
    const location = useLocation();
    const user = useContext<IUser>(UserContext)
    const permission = useContext<IPermissions>(PermissionContext)
    const [showLoader, setShowLoader] = useState(false);
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);
    const [searchParams] = useSearchParams();
    const assId = searchParams.get("assistantId");
    const assistantId = React.useRef(assId ? assId : "");
    const [disableRefreshButton, setDisableRefreshButton] = useState(false)


    const getAssistantById = async () => {
        // setShowLoader(true);
        setDisableRefreshButton(true)
        AgentService.GetAssistantById(assistantId.current)
            .then((res) => {
                const data: IAgentService = res.response;
                SetUpdateAssistant((preVal) => ({
                    ...data,
                }));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                // setShowLoader(false);
                setDisableRefreshButton(false)

            });
    };
    const getTemplateById = async () => {
        // setShowLoader(true);
        setDisableRefreshButton(true)
        TemplateService.GetTemplateById(TemplateId?TemplateId:'')
            .then((res) => {
                const data: IAgentService = res.response;
                SetUpdateAssistant((preVal) => ({
                    ...data,
                }));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                // setShowLoader(false);
                setDisableRefreshButton(false)

            });
    };

    const handleDropFiles = (e: React.DragEvent<HTMLInputElement>) => {
        e.preventDefault();
        const selectedFiles = Array.from(e.dataTransfer.files);
        handleFiles(selectedFiles);
    };

    const handleFilesClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(e.target.files || []);
        handleFiles(selectedFiles);
    };

    const handleFiles = (selectedFiles: File[]) => {
        if (!permission.uploadAgentFiles) return;
        const maxSize = 25 * 1048576;
        const fileSizeArr: number[] = []
        let returnLarger = (arr: number[], num: number) => arr.filter(n => n > num);
        const allowedFileExtensions = ["txt", "doc", "docx", "xls", "xlsx", "csv", "pdf", "jpg", "png"]
        const filteredFiles = selectedFiles.filter(file => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            return fileExtension && allowedFileExtensions.includes(fileExtension);
        });
        let exten: any[] = []
        UpdateAssistant?.files?.map((file: any) => {
            var ext = file.name.substr(file.name.lastIndexOf('.') + 1);
            exten.push(ext)
        })
        selectedFiles?.map((file: any) => {
            var ext = file.name.substr(file.name.lastIndexOf('.') + 1);
            exten.push(ext)
            fileSizeArr.push(file.size)
        })
        if (returnLarger(fileSizeArr, maxSize).length > 0) {
            toastError("File size is geater than 25MB")
            return
        }
        const counts = exten.reduce((acc, name) => {
            acc[name] = (acc[name] || 0) + 1;
            return acc;
        }, {});

        if ((counts['csv'] > 1 || counts['xlsx'] > 1 || counts['xls'] > 1) || (counts['csv'] >= 1 && counts['xlsx'] >= 1 || counts['xls'] >= 1)) {
            toastError("Please upload only one .xlsx,.xls or .csv file at a time");
            return
        }

        SetUpdateAssistant((prevState) => {
            if (filteredFiles.length !== selectedFiles.length) {
                toastWarning("Invalid file type.Please choose files with supported formats.");
            }
            const updatedFiles = [...(prevState.files || []), ...filteredFiles]
            DisableSubmitButton.current = false
            return {
                ...prevState,
                files: updatedFiles.reverse(),
            };
        });
        DisableSubmitButton.current = false

        // if (filteredFiles.length === selectedFiles.length) {
        //     SetUpdateAssistant((prevState) => {
        //         const updatedFiles = [...(prevState.files || []), ...filteredFiles]
        //         DisableSubmitButton.current = false
        //         return {
        //             ...prevState,
        //             files: updatedFiles.reverse(),
        //         };
        //     });
        //     DisableSubmitButton.current = false
        // } else {
        //     toastError("Invalid file type.Please choose files with supported formats.");
        // }
    };


    const fileDeleteConfirm = (fileItem: File | any, index: number) => {
        confirmAlert({
            title: "Confirm Delete",
            message: "Do you want to delete the file?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () =>
                        deleteFile(fileItem, index)
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        })
    };

    const deleteFile = (fileItem: File | IAgentFile, index: number) => {
        if (!permission.deleteAgentFiles) return;
        if (ISAgentFile(fileItem)) {
            let name = fileItem.name ? fileItem.name : ""
            // SetDeletedFileIds((preVal) => ([...preVal, name]))
            setShowLoader(true)
            AgentService.AssistantFileDelete(assistantId.current, name).then(res => {
                toastSuccess("File Deleted Successfully")
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setShowLoader(false)
            })
        }
        const updatedFiles = [...(UpdateAssistant.files || [])]
        updatedFiles.splice(index, 1);

        SetUpdateAssistant((prevState) => ({
            ...prevState,
            files: updatedFiles,
        }));
        DisableSubmitButton.current = false

    };
    const handleDownloadFile = (fileItem: File | any, index: number) => {

    }
    return (<>

        <div className="commonDiv">
            <div className="titleBar d-flex">
                {/* <h3>Upload Files <span>({filesCount})</span></h3> */}
                <h3>Uploaded Files <span>({UpdateAssistant.files?.length})</span></h3>
                <p className="mb-0 ms-auto danger"> File size should be less than 25 MB</p>

            </div>
            <div className="content">
                <div className="drag_drop_container" onDrop={handleDropFiles} onDragOver={(event) => event.preventDefault()}>
                    <div className="leftContainer" onClick={() => fileInputRef?.current?.click()}>
                        <input
                            ref={fileInputRef}
                            type="file"
                            name="files"
                            multiple
                            accept=".txt, .doc, .docx, .xls, .xlsx, .csv, .pdf, .jpg, .png"
                            onChange={(e) => handleFilesClick(e)}
                            style={{ display: "none" }}
                        />
                        <div className="drop-file-upload">
                            <div className="upload-icon">
                                <svg width="100" height="64.156" viewBox="0 0 100 64.156">
                                    <path id="Path_725" data-name="Path 725" d="M34.907,16.176A24.737,24.737,0,0,1,80.372,28.054a20.486,20.486,0,1,1,.794,40.964H28.087a26.857,26.857,0,1,1,6.819-52.841M65.855,40.394a3.5,3.5,0,0,1-4.928.546l-5.983-4.792V52.216a3.5,3.5,0,0,1-7.006,0V36.149L41.95,40.936a3.5,3.5,0,0,1-4.377-5.474l11.668-9.328a3.489,3.489,0,0,1,2.172-.78h.037a3.463,3.463,0,0,1,2.209.794l11.649,9.314a3.5,3.5,0,0,1,.546,4.928" transform="translate(-1.255 -4.862)" fill="#a9cceb" fill-rule="evenodd" />
                                </svg>
                            </div>
                            <h3>Drag & drop or click here</h3>
                            <p>Supports: .txt, .doc, .docx, .xls, .xlsx, .csv, .pdf, .jpg, .png</p>
                        </div>

                    </div>
                    <div className="rightContainer">
                        <div className="uploadHead">
                            <div className="checkBox">
                                {/* <div className="chck-holder">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id=""
                                    />
                                    <div className="checkDesignHolder">
                                        {" "}
                                        <span>
                                            <LuCheck />
                                        </span>
                                    </div>
                                </div>
                                <label>Select All</label> */}
                            </div>
                            {location.pathname !== "/Template/Create" &&
                                <button type="submit" className="btn btn-secondary small splBtn" onClick={() => getAssistantById()}
                                    disabled={disableRefreshButton || UpdateAssistant.files?.length == 0}><LuRotateCw /></button>
                            }
                            {location.pathname == "/Template/Create" &&
                                <button type="submit" className="btn btn-secondary small splBtn" onClick={() => getTemplateById()}
                                    disabled={disableRefreshButton || UpdateAssistant.files?.length == 0}><LuRotateCw /></button>
                            }
                        </div>
                        {UpdateAssistant.files?.map((fileItem: any, index: number) => {
                            const { filename, fullfilename } = getFileName(fileItem);
                            const fileSize = getFileSize(fileItem)
                            const trainedStatus = getTrainingStatus(fileItem)

                            return (

                                <div className="repeat-container">
                                    {/* <div className="chck-holder">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id=""
                                        />
                                        <div className="checkDesignHolder">
                                            {" "}
                                            <span>
                                                <LuCheck />
                                            </span>
                                        </div>
                                    </div> */}
                                    {/* // New 0, Not started 1, Training 2, complete 3, Failed 4  css make for four status */}
                                    <div className={trainedStatus == 0 || trainedStatus == 1 ? "repeat new" :
                                        trainedStatus == 2 ? "repeat inprogress" :
                                            trainedStatus == 3 ? "repeat completed" : "repeat failed"
                                    } key={index}>
                                        {/*For only development(inprogress,completed,failed) */}
                                        <div className="file-name">
                                            <div className="status">
                                                {trainedStatus == 0 || trainedStatus == 1 ? <LuLoader /> :
                                                    trainedStatus == 2 ? <IoCheckmarkCircle /> :
                                                        trainedStatus == 3 ? <IoCheckmarkCircle /> :
                                                            <IoCloseCircle />}
                                            </div>
                                            <p title={fullfilename}>{filename}</p>
                                        </div>
                                        <div className="file-size">
                                            <p>{fileSize}</p>
                                        </div>
                                        <div className="file-action">
                                            <div className="trained-label">
                                                {<><label>{trainedStatus == 0 ? "New" : fileItem.trainingStatus}</label>
                                                    {trainedStatus == 0 ? <LuMinusCircle /> :
                                                        trainedStatus == 1 ? <LuCheckCircle2 /> :
                                                            trainedStatus == 2 ? <LuCheckCircle2 /> :
                                                                trainedStatus == 3 ? <LuCheckCircle2 /> : <LuXCircle />
                                                    }
                                                </>}
                                            </div>
                                            <button
                                                type="button"
                                                title="View"
                                                onClick={() => handleDownloadFile(fileItem, index)}
                                            >
                                                <LuEye />
                                            </button>
                                            <button
                                                type="button"
                                                title="Delete"
                                                onClick={() =>
                                                    fileDeleteConfirm(fileItem, index)
                                                }
                                            >
                                                <LuTrash />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );

                        })}
                    </div>

                </div>
            </div>
        </div>
        <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>)
}

export default UploadFiles