import React, { useState, useEffect, useRef, useContext } from "react";
import DataTable, { TableColumn } from 'react-data-table-component';
import { toastError, toastSuccess } from "../../Toast/Toast";
import IApiKey from "../../../Services/APIKeyServices/IApiKey";
import ApiKeyService from "../../../Services/APIKeyServices/ApiKeyService";
import { FaTimes, FaPlus } from 'react-icons/fa';
import { ThreeDotLoader } from "../../Loader/Loader";
import { LuTrash2, LuEye, LuCode2 } from "react-icons/lu";

import './ApiKey.scss';
import { REDIRECT_URL } from "../../../Services/EndPoint";
import { confirmAlert } from "react-confirm-alert";
import { useSearchParams } from "react-router-dom";
import IAgentService from "../../../Services/AgentServices/IAgentService";
import UserContext from "../../../Context/UserContext/UserContext";
import IUser from "../../../Services/UserManagement/IUserManagementService";
import { IPermissions } from "../../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../../Context/PermissionContext/PermissionContext";
import { ASSISTANT_INITIAL_OBJ } from "../../../Constants/constants";
interface Props {

}

const ApiKey: React.FC<Props> = ({ }) => {
  const [searchParams] = useSearchParams();
  const assId = searchParams.get('assistantId')
  const assistantId = React.useRef(assId ? assId : '');

  const user = useContext<IUser>(UserContext)
  const permission = useContext<IPermissions>(PermissionContext)

  const [showLoader, setshowLoader] = useState(false);
  const [showKeysFormPopup, setshowKeysFormPopup] = useState(false);
  const [createNewKey, setCreateNewKey] = useState(false);
  const clickedPartIndexRef = useRef(0);
  const [rowData, setRowData] = useState({});
  const [assistant, setAssistant] = useState<IAgentService>(ASSISTANT_INITIAL_OBJ)
  const [tableData, setTableData] = useState<IApiKey[]>();
  const [iFrameData, setIFrameData] = useState("");
  const [showiFramePopup, setshowiFramePopup] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [createItemForm, setCreateItemForm] = useState<IApiKey>({
    name: "",
    assistant: assistantId.current,
  });


  useEffect(() => {
    // if(!permission.viewApiKeyList) return;
    getAllKeys();
  }, []);

  const getAllKeys = () => {
    setshowLoader(true);
    ApiKeyService.GetAllApiKeysByAssistantId(assistantId.current).then(res => {
      console.log("res.response", res.response)
      let data = res.response
      // setAssistant(data)
      setTableData(data ? data : [])
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setshowLoader(false)
    })
  };

  const handleCreateApiKey = (e: any) => {
    e.preventDefault();
    if (validateAgentData()) {
      setshowLoader(true);
      setButtonDisable(true)
      ApiKeyService.CreateApiKey(createItemForm).then(res => {
        toastSuccess("Api Key Created Successfully")
        closeEosPopup();
        getAllKeys()
      }).catch(err => {
        setButtonDisable(false)
        toastError(err?.response?.data?.response)
        console.log(err)
      }).finally(() => {
        setshowLoader(false)

      })

    }
  };

  const handleDeleteApiKey = (apiKeyObj: IApiKey) => {
    setshowLoader(true)
    ApiKeyService.DeleteApiKey(apiKeyObj).then((res) => {
      toastSuccess("Api Key Deleted Successfully")
      getAllKeys()
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setshowLoader(false)
    })
  }

  const handleCreateNewKey = () => {
    if(!permission.createApiKey) return;
    setCreateNewKey(true);
    setshowKeysFormPopup(true);
    setButtonDisable(false)
    setCreateItemForm({
      name: "",
      assistant: assistantId.current
    });
  };



  const tableColumns: TableColumn<any>[] = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Assistant Id",
      selector: (row) => assistantId.current,
      // sortable: true,
    },
    {
      name: "Key",
      selector: (row) => row.token,
      sortable: true,
    },
    // {
    //   name: "Created User",
    //   selector: (row: IApiKey) => row.CreatedUser,
    //   sortable: true,
    // },
    // {
    //   name: "Created Date",
    //   selector: (row: IApiKey) => new Date(row.CreatedDate).toLocaleDateString(),
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "Updated Date",
    //   selector: (row: IApiKey) => row.UpdatedDate,
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: " ",
      width: "100px",
      sortable: false,
      button: true,
      right: true,
      cell: (row, rowIndex) => (
        <div className="gridIcons" data-tag="allowRowEvents">
          <>
            <>
              <button
                type="button"
                className="btn plainBtn editBtn"
                title="View Item"
                onClick={() => {
                  showKeysEditFormPopup(row);
                  clickedPartIndexRef.current = rowIndex;
                }}
              >
                <LuEye />
              </button>
              <button
                type="button"
                className="btn plainBtn deleteBtn"
                title="Delete"
                disabled={!permission.deleteApiKey}
                onClick={() => {
                  row.assistant = assistantId.current
                  deleteKeyConfirm(row)
                }}
              >
                <LuTrash2 />
              </button>

              <button
                type="button"
                className="btn plainBtn deleteBtn"
                title="Embed"
                onClick={() => handleEmbedOnSite(row)}
              >
                <LuCode2 />
              </button>
            </>
          </>
        </div>
      ),
    }
  ];

  const updateForm = (target: any) => {
    let name = target.name;
    let val = target.value;
    setCreateItemForm({
      ...createItemForm,
      [name]: val,
    });
  };

  const validateAgentData = () => {
    let flag = true;
    if (!createItemForm.name?.trim()) {
      toastError("Please Enter Name");
      flag = false;
    }
    if (!createItemForm.assistant?.trim()) {
      toastError("Please Enter Key");
      flag = false;
    }
    return flag;
  };




  const deleteKeyConfirm = (apiKeyObj: IApiKey) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Do you want to delete the key ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleDeleteApiKey(apiKeyObj)
          }
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    })

  };

  const showKeysEditFormPopup = (row: IApiKey) => {
    setshowKeysFormPopup(true);
    setRowData(row);
    setButtonDisable(false)
    setCreateNewKey(false)
    setCreateItemForm({
      name: row.name,
      assistant: assistantId.current,
      token: row.token,
    });
  };

  const hideKeysFormPopup = () => {
    setshowiFramePopup(false);
    setshowKeysFormPopup(false);
    setCreateNewKey(false);
  };

  const closeEosPopup = () => {
    setshowiFramePopup(false);
    setshowKeysFormPopup(false);
  };

  const handleEmbedOnSite = (item: IApiKey) => {
    // let iframeVal = ` <iframe src="${REDIRECT_URL}/bot/${item.token}/${assistantId.current}"
    let iframeVal = ` <iframe src="${REDIRECT_URL}/botPreview/${item.token}/${assistantId.current}"
    id="${item.token}" width="450px" height="80%" frameborder="0"
    style="height:80vh; width=450px; position: fixed; right: 20px; bottom:80px; z-index: 99999; display: none;"></iframe>
  
      <button id="loadBotButton"
      style="
	  position: fixed; 
	  background: none; 
	  right: 20px; 
	  bottom: 20px; 
	  z-index: 999990; 
	  border: 0; 
	  width:40px; 
	  height: 40px; 
	  border-radius: 25px;
	  cursor: pointer;
	  -webkit-box-shadow: 1px 1px 23px -11px rgba(0,0,0,0.51);
-moz-box-shadow: 1px 1px 23px -11px rgba(0,0,0,0.51);
box-shadow: 1px 1px 23px -11px rgba(0,0,0,0.51);
	  "
        onclick="toggleBot()">
        <img width="100%" src="${REDIRECT_URL}/logo.png" >
      </button>

        <script>
          function toggleBot() {
            var iframe = document.getElementById("${item.token}");
            if (iframe.style.display === "none" || iframe.style.display === "") {
              iframe.style.display = "block";
            } else {
              iframe.style.display = "none";
            }
          }
        </script>`
    setIFrameData(iframeVal);
    setshowiFramePopup(true);
  };

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    toastSuccess('Text Copied Successfully');
  };





  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className="mb-0">API Keys</h3>
        {/* {!showKeysFormPopup && !createNewKey && ( */}
        <button
          className="btn btn-primary small ms-auto"
          type="button"
          disabled={!permission.createApiKey}
          onClick={handleCreateNewKey}
        >
          <FaPlus />New Key
        </button>
        {/* )} */}
      </div>

      <div className="dataTable">
        {tableData && (
          <DataTable
            noHeader={true}
            columns={tableColumns}
            data={tableData}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 15, 20]}
          />
        )}
      </div>


      {showKeysFormPopup && (
        <div className="popup-container small-popup">
          <div className="shadeClose" onClick={() => {
            hideKeysFormPopup();
          }}></div>
          <div className="popup-inner">
            <div className="inner-design">
              <div className="popup-header">
                <h3>{createNewKey ? "Create New Key" : "Key Details"}</h3>
                <button
                  type="button"
                  onClick={() => {
                    hideKeysFormPopup();
                  }}
                  className="close"
                ><FaTimes /></button>
              </div>

              <div className="popup-content">

                {/* <form onSubmit={fromUploadPage ? editForm : createForm}> */}
                <form onSubmit={handleCreateApiKey}>
                  <div className="col-12 col-md-12 formHolder nameHolder mb-4">
                    <label className="form-label">
                      Name <span className="danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter the name"
                      name="name"
                      className="form-control"
                      onChange={(event) => updateForm(event.target)}
                      disabled={!createNewKey}
                      value={createItemForm.name}
                    />
                  </div>

                  <div className="col-12 col-md-12 formHolder keyHolder mb-4">
                    <label className="form-label">
                      Assistant Id <span className="danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter the key"
                      name="AssistantId"
                      className="form-control"
                      onChange={(event) => updateForm(event.target)}
                      disabled={!createNewKey}
                      readOnly={createItemForm.assistant ? true : false}
                      value={createItemForm.assistant}
                    />
                  </div>

                  {!createNewKey && (
                    <div>
                      <div className="col-12 col-md-12 formHolder keyHolder mb-4">
                        <label className="form-label">
                          Key<span className="danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="ApiKey"
                          className="form-control"
                          disabled={!createNewKey}
                          value={createItemForm.token}
                        />
                      </div>

                      {/* <div className="col-12 col-md-12 formHolder keyHolder mb-4">
                        <label className="form-label">
                          Created User<span className="danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="CreatedUser"
                          className="form-control"
                          disabled={!createNewKey}
                        // value={createItemForm.CreatedUser}
                        />
                      </div>

                      <div className="col-12 col-md-12 formHolder keyHolder mb-4">
                        <label className="form-label">
                          Created Date<span className="danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="CreatedDate"
                          className="form-control"
                          disabled={!createNewKey}
                          value={createItemForm.CreatedDate}
                        />
                      </div> */}
                    </div>
                  )}
                  {createNewKey && (
                    <div className="d-flex gap-2 mt-2">
                      <button
                        type="submit"
                        className="btn btn-secondary small"
                        onClick={() => {
                          hideKeysFormPopup();
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary small" disabled={buttonDisable}>
                        {createNewKey ? "Create" : "Update"}
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>)}


      {showiFramePopup && (
        <div className="popup-container medium-popup">
          <div className="shadeClose" onClick={() => {
            closeEosPopup();
          }}></div>
          <div className="popup-inner">
            <div className="inner-design">
              <div className="popup-header">
                <h3>Embed on site</h3>
                <button
                  type="button"
                  onClick={() => {
                    closeEosPopup();
                  }}
                  className="close"
                ><FaTimes /></button>
              </div>

              <div className="popup-content">
                <label className="form-label">
                  <strong>iFrame <span className="danger">*</span></strong>
                </label>

                <div className="input-group">
                  <div className="iframeCodeHolder">
                    <textarea
                      placeholder="Enter the iframe"
                      name="iFrame"
                      className="form-control"
                      onChange={(event) => updateForm(event.target)}
                      value={iFrameData}
                    />
                    <button
                      type="button"
                      className="btn"
                      onClick={() => copyToClipboard(iFrameData)}
                    >
                      Copy
                    </button>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      )}


      <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>
  );
};

export default ApiKey;













// const showKeysEditFormPopup = (row: any) => {
//   setshowKeysFormPopup(true);
//   setRowData(row);
//   setCreateItemForm({
//     Id: row.Id !== undefined ? row.Id : "",
//     name: row.name,
//     apiKey: row.apiKey,
//     assistantId: row.assistantId,
//     createdUser: row.createdUser,
//     updatedUser: row.updatedUser,
//     CreatedDate: row.CreatedDate,
//     updatedDate: row.updatedDate
//   });
// };

// const handlePageSize = (pageRows: any) => {
//   setPageSize(pageRows);
// };

// const handlePageNumber = (pageNumber: any) => {
//   setPageNumber(pageNumber);
// };

// const editForm = (e: any) => {
//   e.preventDefault();
//   if (validateAgentData()) {
//     setshowLoader(true);
//     const currentDate = new Date();
//     const formattedDate = currentDate.toISOString();
//     createItemForm.updatedDate = formattedDate;
//     console.log("updateAssistant", createItemForm);
//     ApiKeyService.UpdateAPIKey(createItemForm)
//     .then((res: any) => {
//         console.log("updateAssistant-res", res);
//         if (res) {
//           toastSuccess("Item Created Successfully");
//         } else {
//           toastWarning("Response Error")
//         }
//         setshowLoader(false);
//       })
//       .catch((error: Error) => {
//         toastError("Error in creation");
//         console.log(error);
//         setshowLoader(false);
//       });
//   }
// };
