import React, { Dispatch, useEffect, useState } from "react";
import ITemplateService from "../../../Services/TemplateService/ITemplateService";
import TemplateService from "../../../Services/TemplateService/TemplateService";
import { LuCheck, LuChevronDown, LuEye, LuFileText } from "react-icons/lu";
import { deleteTemplateData, saveTemplateData } from "../../../Stores/TemplateStore/TemplateAction";
import { FaTimes } from "react-icons/fa";
import { toastError, toastSuccess, toastWarning } from "../../Toast/Toast";
import IAgentService from "../../../Services/AgentServices/IAgentService";
import DataTable, { TableColumn } from "react-data-table-component";
import { connect, useDispatch, useSelector } from "react-redux";

interface Props {
    SetShowTemplateFormPopup: React.Dispatch<React.SetStateAction<boolean>>;
    SetCreateItemForm: React.Dispatch<React.SetStateAction<IAgentService>>;
    SelectedTemplate: string[]
    SetSeletedTemplate: React.Dispatch<React.SetStateAction<string[]>>;
    GroupName: string
    makeEmpty?:any



}

const TemplateInfo: React.FC<Props> = ({ GroupName, SetShowTemplateFormPopup, SetCreateItemForm, SelectedTemplate, SetSeletedTemplate,makeEmpty }) => {
    const [templateList, setTemplateList] = useState<ITemplateService[]>([]);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedTab, setSelectedTab] = useState("files");
    const [showTemplate, setShowTemplate] = useState<ITemplateService>();
    const [selectedTemplateId, setSelectedTemplateId] = useState("");

    const [openSection, setOpenSection] = useState<number | null>(null);
    const dispatch = useDispatch()


    useEffect(() => {
        getAllTemplates(GroupName)
        console.log("template info")
    }, [])


    const getAllTemplates = (groupName: string) => {
        setShowLoader(true);
        setTemplateList([]);
        if (groupName) {
            TemplateService.GetAllTemplpates(groupName).then(res => {
                setTemplateList(res.response)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setShowLoader(false);
            })
        }
    }
    const viewTemplateDetails = (templateId: any) => {
        setSelectedTab("files")
        const template = templateList.find(template => template.id === templateId);
        if (template) {
            setShowTemplate(template);
        }
        setSelectedTemplateId(templateId);
    }

    const toggleTemplateSelection = (templateId: string, action: any) => {
        if (SelectedTemplate.length === 0 || !action.checked) {
            SetSeletedTemplate((preVal => {
                if (action.checked) {
                    return [...preVal, templateId]
                } else {
                    // makeEmpty()
                    let updatedTemplateLst = preVal?.filter((item, index) => item !== templateId)
                    let singleTemplateObj = templateList?.find((item) => item.id === templateId);
                    SetCreateItemForm((preVal) => {
                        return { ...preVal, templateId: '', templateName: "", instruction: "" }
                    })
                    return [...updatedTemplateLst]
                }
            }))

            setSelectedTab("files")
            const template = templateList.find(template => template.id === templateId);
            if (template) {
                setShowTemplate(template);
            }

            setSelectedTemplateId(templateId);
        }
    };

    const toggleSection = (index: number) => {
        setOpenSection(openSection === index ? null : index);
    };

    const handleApply = () => {
        if (SelectedTemplate.length == 1) {
            toastSuccess("Templates Applied Successfully");
            let templateId = SelectedTemplate[0]
            let singleTemplateObj = templateList?.find((item) => item.id === templateId);
            SetCreateItemForm((preVal) => {
                SetShowTemplateFormPopup(false)
                return { ...preVal, templateId: templateId, templateName: singleTemplateObj ? singleTemplateObj.name : "", instruction: singleTemplateObj ? singleTemplateObj.instruction : "" }
            })
        } if (SelectedTemplate.length === 0) {
            toastError("Please select a template");
        }
        if (SelectedTemplate.length > 1) {
            toastWarning("Please select a single template");
        }
    };


    const cancelTemplateForm = () => {
        SetShowTemplateFormPopup(false)
    };

    React.useEffect(() => {
        if (templateList.length > 0) {
            setShowTemplate(templateList[0]);
        }
    }, [templateList]);



    const tableColumns: TableColumn<any>[] = [

        {
            name: "Name",
            selector: (row: any) => (
                <div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" }} title={row.connectionName}>
                    {row.connectionName}
                </div>
            ),
            sortable: true,
            width: "100px",
        },
        {
            name: "Database Type",
            selector: (row: any) => row.databaseType,
            sortable: true,
            width: "150px",
        },
        {
            name: "Server",
            selector: (row: any) => row.server,
            sortable: true,
            cell: (row: any) => (<div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={row.server}>
                {row.server.length > 50 ? `${row.server.substring(0, 50)}...` : row.server}
            </div>
            ),
        },
        {
            name: "Database",
            selector: (row: any) => row.databaseName,
            sortable: true,
            width: "200px",
        },
        {
            name: "User ID",
            selector: (row: any) => row.user,
            sortable: true,
            width: "200px",
        },
        {
            name: "Port",
            selector: (row: any) => row.port,
            sortable: true,
            width: "100px",
        },
    ];

    const GHTableColumn: TableColumn<any>[] = [
        {
            name: "Name",
            selector: (row: any) => row.repoName,
            sortable: true,
            width: "250px",
            cell: (row: any) => (<div style={{ maxWidth: "250px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={row.repoName}>
                {row.repoName.length > 25 ? `${row.repoName.substring(0, 25)}...` : row.repoName}
            </div>
            ),
        },
        {
            name: "Access Token",
            selector: (row: any) => row.accessToken,
            sortable: true,
            width: "175px",
            cell: (row: any) => (<div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={row.accessToken}>
                {row.accessToken.length > 10 ? `${row.accessToken.substring(0, 10)}...` : row.accessToken}
            </div>
            ),
        },
        {
            name: "Branch",
            selector: (row: any) => row.branch,
            sortable: true,
            cell: (row: any) => (<div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={row.branch}>
                {row.branch.length > 50 ? `${row.branch.substring(0, 50)}...` : row.branch}
            </div>
            ),
        },
        {
            name: "Repo Owner",
            selector: (row: any) => row.repoOwner,
            sortable: true,
            width: "200px",
        },
    ]



    return (<>
        <div className="popup-container large-popup" >
            <div className="popup-inner template-section">
                <div className="inner-design">
                    <div className="d-flex">
                        <div className="col-md-4 p-0 template-list-holder" >
                            <div className="popup-header d-block">
                                <h3>Templates</h3>
                                <h6 className="smallest-font mb-3">
                                    <span>
                                        <em>{GroupName}</em>
                                    </span>
                                </h6>
                            </div>

                            <div className="template-lists">
                                <div className="popup-content">
                                    {templateList && templateList.map((template, index) => (
                                        // <div className="mb-4 boxHolder selectedTemplate">
                                        // <div className={`mb-4 boxHolder ${index === 0 ? 'selectedTemplate' : ''}`}>

                                        <div className={`mb-4 boxHolder ${selectedTemplateId === template.id || (selectedTemplateId === "" && index === 0) ? 'selectedTemplate' : ''}`} key={template.id}>
                                            <div className="box justify-content-between">
                                                <div className="d-flex gap-2 title-bar align-items-center">
                                                    <div className="iconHolder">
                                                        <LuFileText />
                                                    </div>
                                                    <div className="info-holder d-flex flex-column">
                                                        <label className="form-check-label">{template.name}</label>
                                                        <span>{template.createdDate}</span>
                                                    </div>
                                                </div>
                                                <p className="instruction" title={template.name}>{template.instruction?.slice(0, 50)}{String(template.instruction)?.length > 50 ? '...' : ''}</p>
                                                <div className="bottom-holder d-flex gap-3">
                                                    <span className="tag">{template.group}</span>
                                                    <div className="ms-auto">
                                                        <div className="d-flex gap-3">
                                                            <button className="btn icon-btn" title="View" onClick={() => viewTemplateDetails(template.id)}>
                                                                <LuEye />
                                                            </button>
                                                            <div className="chck-holder">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    onClick={(e) => toggleTemplateSelection(template.id ? template.id : '', e.target)}
                                                                    checked={(template.id ? SelectedTemplate?.includes(template.id) : false)}
                                                                />
                                                                <div className="checkDesignHolder" >
                                                                    <span><LuCheck /> </span></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}


                                </div>
                                <div className="mt-2 btnHolder d-flex gap-3">
                                    <button type="button" className="btn btn-secondary" onClick={cancelTemplateForm}>Cancel</button>
                                    <button type="button" className="btn btn-primary" onClick={handleApply} disabled={(SelectedTemplate.length == 0 ? true : false)}>Apply</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 p-0">
                            <div className="popup-header">
                                <h3>Template Heading</h3>
                                <button
                                    type="button"
                                    onClick={() => cancelTemplateForm()}
                                    className="close"
                                ><FaTimes /></button>
                            </div>
                            <div className="popup-content">
                                <div className="tabHeader">
                                    <div
                                        className={`tabButton ${selectedTab === "files" ? "active" : ""}`}
                                        onClick={() => setSelectedTab("files")}
                                    >
                                        Files
                                    </div>

                                    <div
                                        className={`tabButton ${selectedTab === "website" ? "active" : ""}`}
                                        onClick={() => setSelectedTab("website")}

                                    >
                                        Website
                                    </div>
                                    {/* <div
                                        className={`tabButton ${selectedTab === "qna" ? "active" : ""}`}
                                        onClick={() => setSelectedTab("qna")}

                                    >
                                        FAQ
                                    </div> */}

                                    <div
                                        className={`tabButton ${selectedTab === "ds" ? "active" : ""}`}
                                        onClick={() => setSelectedTab("ds")}

                                    >
                                        Database
                                    </div>

                                    <div
                                        className={`tabButton ${selectedTab === "prompt" ? "active" : ""}`}
                                        onClick={() => setSelectedTab("prompt")}

                                    >
                                        Prompt
                                    </div>
                                    <div
                                        className={`tabButton ${selectedTab === "github" ? "active" : ""}`}
                                        onClick={() => setSelectedTab("github")}

                                    >
                                        Github
                                    </div>
                                </div>
                                <div className="tabContent">
                                    <div className="topContent">
                                        {selectedTab === "files" && (
                                            <div className="commonDiv files-content">
                                                {showTemplate?.files?.length === 0 ? (
                                                    <div className="content">
                                                        <center>There are no records to display</center>
                                                    </div>
                                                ) : (
                                                    <div className="content">
                                                        <div className="row">
                                                            {showTemplate?.files?.map((item: any) => (
                                                                <div className="col-12 col-md-6 repeat mb-3">
                                                                    <div className="box" >
                                                                        <p title={item.name}>{item.name}</p>
                                                                        {/* <a href={`${REDIRECT_URL}/${item.fileName}`} download={item.name} title="Download">
                                                                          
                                                                                </a> */}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {selectedTab === "website" && SelectedTemplate && (
                                            <div className="commonDiv websiteContent">
                                                {showTemplate?.website?.length === 0 ? (
                                                    <div className="content">
                                                        <center>There are no records to display</center>
                                                    </div>
                                                ) : (
                                                    <div className="content">
                                                        <div className="row">
                                                            {showTemplate?.website?.map((item: any, index: number) => (
                                                                <div className="col-12 col-md-6 repeat mb-3" key={index}>
                                                                    <a className="box">
                                                                        <p>{item.url}</p>
                                                                    </a>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {/* {selectedTab === "qna" && (
                                            <div className="commonDiv qnaContent">
                                                {showTemplate?.qAndAList?.length === 0 ? (
                                                    <div className="content accordionContainer">
                                                        <center>There are no records to display</center>
                                                    </div>
                                                ) : (
                                                    <div className="content accordionContainer" >
                                                        {showTemplate?.qAndAList?.map((section: any, index: any) => (
                                                            <div className="repeatDiv" key={index}>
                                                                <div
                                                                    className="accordion-header"
                                                                    onClick={() => toggleSection(index)}
                                                                    style={{ cursor: 'pointer', marginBottom: '5px' }}
                                                                >
                                                                    <strong>{section.question}</strong>
                                                                    <div className="accordionTrgr" >
                                                                        <LuChevronDown />
                                                                    </div>
                                                                </div>
                                                                {openSection === index && (
                                                                    <div className="accordion-content">{section.answer}</div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )} */}

                                        {selectedTab === "ds" && showTemplate?.databaseConnections && (
                                            <div className="commonDiv">
                                                {showTemplate?.databaseConnections.length === 0 ? (
                                                    <div className="content">
                                                        <center>There are no records to display</center>
                                                    </div>
                                                ) : (
                                                    <div className="content">
                                                        <DataTable
                                                            noHeader={true}
                                                            columns={tableColumns}
                                                            data={showTemplate.databaseConnections}
                                                            // pagination
                                                            // paginationServer
                                                            pagination={true}
                                                            paginationPerPage={10}
                                                            paginationRowsPerPageOptions={[10, 15, 20]}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {selectedTab === "prompt" && showTemplate && (
                                            <div className="commonDiv promtSelection">
                                                {showTemplate?.prompts?.length === 0 ? (
                                                    <div className="content">
                                                        <center>There are no records to display</center>
                                                    </div>
                                                ) : (
                                                    <div className="content">
                                                        <div className="row">
                                                            {showTemplate?.prompts?.map((prompt: any) => (
                                                                <div className="col-12 col-md-6 col-lg-6 mb-3 boxHolder">
                                                                    <div className="box">
                                                                        <div className="d-flex gap-3 title-bar align-items-center">
                                                                            <div className="iconHolder">
                                                                                <LuFileText />
                                                                            </div>
                                                                            <label className="form-check-label">
                                                                                {prompt.name}
                                                                            </label>

                                                                        </div>
                                                                        <p className="description">
                                                                            {prompt.description}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {selectedTab === "github" && (
                                            <div className="commonDiv">
                                                <div className="dataTable">
                                                    {showTemplate?.github && (
                                                        <DataTable
                                                            noHeader={true}
                                                            columns={GHTableColumn}
                                                            data={showTemplate?.github ? showTemplate?.github : []}
                                                            // pagination
                                                            // paginationServer
                                                            pagination={true}
                                                            paginationPerPage={10}
                                                            paginationRowsPerPageOptions={[10, 15, 20]}
                                                        />
                                                    )}
                                                </div>


                                            </div>
                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>)

}


const mapStateToProps = (state:any)=>{

}
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    makeEmpty: () => dispatch(deleteTemplateData()),
  });

export default connect(mapStateToProps, mapDispatchToProps)(TemplateInfo)
