import React, { useState, useEffect, useContext } from "react";
import { ThreeDotLoader } from "../Loader/Loader";
import { LuTrash2 } from "react-icons/lu";
import { toastSuccess } from "../Toast/Toast";
import { confirmAlert } from "react-confirm-alert";
import { LuFileText, LuEye } from "react-icons/lu";
import { FaTimes } from 'react-icons/fa';
import TemplateService from "../../Services/TemplateService/TemplateService";
import IGroupService from "../../Services/GroupService/IGroupService";
import GroupService from "../../Services/GroupService/GroupService";
import { REDIRECT_URL } from "../../Services/EndPoint";
import DataTable, { TableColumn } from 'react-data-table-component';
import ITemplateService from "../../Services/TemplateService/ITemplateService";
import { useNavigate } from "react-router-dom";
import IUser from "../../Services/UserManagement/IUserManagementService";
import { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";
import UserContext from "../../Context/UserContext/UserContext";
import ReactPaginate from "react-paginate";
// import { permissionState } from "../../Constants/constants";
interface Props {
    agentId: any;
}



const TemplateList: React.FC<Props> = ({ agentId }) => {

    const user = useContext<IUser>(UserContext)
    const permission = useContext<IPermissions>(PermissionContext)

    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showTemplateDetails, setShowTemplateDetails] = useState(false);
    const [selectedTab, setSelectedTab] = useState("files");
    const [openSection, setOpenSection] = useState<number | null>(null);
    const [templateList, setTemplateList] = useState<any[]>([]); // Initialize as an array of any type
    const [groupList, setGroupList] = useState<IGroupService[]>([]);
    const [templateFileList, setTemplateFileList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
    const [pageSize, setPageSize] = useState(12)
    const [pageNumber, setPageNumber] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [activeTab, setActiveTab] = useState('All');
    const navigate = useNavigate();


    useEffect(() => {
        // if (!permission.viewTemplate && !permission.createTemplate) return;
        // if (permission.createTemplate) GetAllGroups();
        // if (permission.viewTemplate) getAllTemplates();
        getAllTemplates();
        GetAllGroups();
    }, []);
    useEffect(() => {
        getAllTemplates();
    }, [pageNumber]);


    const GetAllGroups = async () => {
        try {
            setShowLoader(true);
            const res = await GroupService.GetGroupList();
            if (res.statusCode === 200) {
                setGroupList(res.response);
            }
        } catch (error) {
            console.log('GetAllGroups', error);
        } finally {
            setShowLoader(false);
        }
    };
    const getAllTemplates = (groupName?: string) => {
        setShowLoader(true);
        TemplateService.GetAllTemplpates(groupName, pageNumber, pageSize).then(res => {
            setTemplateList(res.response)
            setTotalCount(res.count ? res.count : 0)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false);
        })
    }


    const viewTemplateDetails = (templateId: any) => {
        setSelectedTab("files")
        setShowTemplateDetails(true);
        const selectedTemplate = templateList.find(template => template.id === templateId);
        if (selectedTemplate) {
            setSelectedTemplate(selectedTemplate);
        }
    }


    const closeTemplatePopup = () => {
        setShowTemplateDetails(false);
    }

    const toggleSection = (index: number) => {
        setOpenSection(openSection === index ? null : index);
    };


    const tableColumns: TableColumn<any>[] = [
        {
            name: "Name",
            selector: (row: any) => (
                <div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" }} title={row.connectionName}>
                    {row.connectionName}
                </div>
            ),
            sortable: true,
            width: "100px",
        },
        {
            name: "Database Type",
            selector: (row: any) => row.databaseType,
            sortable: true,
            width: "150px",
        },
        {
            name: "Server",
            selector: (row: any) => row.server,
            sortable: true,
            cell: (row: any) => (<div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={row.server}>
                {row.server.length > 50 ? `${row.server.substring(0, 50)}...` : row.server}
            </div>
            ),
        },
        {
            name: "Database",
            selector: (row: any) => row.databaseName,
            sortable: true,
            width: "200px",
        },
        {
            name: "User ID",
            selector: (row: any) => row.user,
            sortable: true,
            width: "200px",
        },
        {
            name: "Port",
            selector: (row: any) => row.port,
            sortable: true,
            width: "100px",
        },
    ];
    const GHTableColumn: TableColumn<any>[] = [
        {
            name: "Name",
            selector: (row: any) => row.repoName,
            sortable: true,
            width: "250px",
            cell: (row: any) => (<div style={{ maxWidth: "250px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={row.repoName}>
                {row.repoName.length > 25 ? `${row.repoName.substring(0, 25)}...` : row.repoName}
            </div>
            ),
        },
        {
            name: "Access Token",
            selector: (row: any) => row.accessToken,
            sortable: true,
            width: "175px",
            cell: (row: any) => (<div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={row.accessToken}>
                {row.accessToken.length > 10 ? `${row.accessToken.substring(0, 10)}...` : row.accessToken}
            </div>
            ),
        },
        {
            name: "Branch",
            selector: (row: any) => row.branch,
            sortable: true,
            cell: (row: any) => (<div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={row.branch}>
                {row.branch.length > 50 ? `${row.branch.substring(0, 50)}...` : row.branch}
            </div>
            ),
        },
        {
            name: "Repo Owner",
            selector: (row: any) => row.repoOwner,
            sortable: true,
            width: "200px",
        },
    ]

    const deleteTemplate = (templateId: string) => {
        setShowLoader(true)
        TemplateService.DeleteTemplate(templateId).then(res => {
            toastSuccess("Template Deleted Successfully")
            getAllTemplates()
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)

        })
    }

    const deleteTemplateConfirm = (templateId: string) => {
        if (!permission.deleteTemplate) return;
        confirmAlert({
            title: "Confirm Delete",
            message: "Do you want to delete the Template ?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        deleteTemplate(templateId)
                    }
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        })
    };


    const handleTabClick = (group?: any) => {
        if (group) {
            setActiveTab(group.name);
            getAllTemplates(group.name);
        } else {
            setActiveTab('All');
            getAllTemplates();
        }
    };


    const goChatBot = async () => {
        navigate('/Template/Create');
    };
    const handlePageClick = (event: any) => {
        setPageNumber(event.selected + 1)
    };



    return (
        <>
            <div className="contentWrapper ">
                <div className="template-section">
                    <div className="d-flex mb-3 gpa-3 align-items-center">
                        <h2 >All Templates({templateList.length})</h2>
                        <div className="ms-auto">

                            <button type="submit" className="ms-auto btn btn-secondary" onClick={() => goChatBot()}>Create Template</button>
                            {/* <div className="filer-tab d-flex gap-3">
                                <a className={activeTab === 'All' ? 'active' : ''} onClick={() => handleTabClick()}>All</a>
                                {groupList && groupList.map((group) => (
                                    <a key={group.name} className={activeTab === group.name ? 'active' : ''} onClick={() => handleTabClick(group)}>
                                        {group.name}
                                    </a>
                                ))}
                            </div> */}

                        </div>
                    </div>

                    <div className="row">
                        {templateList && templateList.map((template: ITemplateService) => (
                            <div className="col-12 col-md-4 col-lg-4 mb-4 boxHolder">
                                <div className="box justify-content-between">
                                    <div className="d-flex gap-2 title-bar align-items-center pe-3">
                                        <div className="templateIconHolder">
                                            <LuFileText />
                                        </div>
                                        <div className="info-holder d-flex flex-column">
                                            <label className="form-check-label">{template.name}</label>
                                            <span>{template.createdDate}</span>
                                        </div>
                                        {/* {permissionState.isDeleteTemplate && ( */}
                                        <div className="icons">
                                            <button
                                                className="close" title="Delete"
                                                onClick={() => deleteTemplateConfirm(template.id ? template.id : '')}
                                            >
                                                <LuTrash2 />
                                            </button>
                                        </div>
                                        {/* )} */}
                                    </div>
                                    <p className="instruction" title={template.instruction}>{template.instruction?.slice(0, 100)}{String(template.instruction)?.length > 100 ? '...' : ''}</p>

                                    <div className="bottom-holder d-flex gap-3">
                                        <span className="tag">{template.group}</span>
                                        <div className="ms-auto">
                                            <div className="d-flex gap-3">
                                                <button className="btn icon-btn" title="View" onClick={() => viewTemplateDetails(template.id)}>
                                                    <LuEye />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}

                        {showTemplateDetails && (
                            <div className="popup-container default-popup template-popup">
                                <div className="shadeClose" onClick={() => {
                                    closeTemplatePopup();
                                }}></div>
                                <div className="popup-inner">
                                    <div className="inner-design">
                                        <div className="popup-header flex-column align-items-start">
                                            <h3 className="mb-1">{selectedTemplate && selectedTemplate.name}</h3>
                                            <h6 className="smallest-font">
                                                <span>
                                                    <em>{selectedTemplate && selectedTemplate.group}</em>
                                                </span>
                                            </h6>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    closeTemplatePopup();
                                                }}
                                                className="close"
                                            ><FaTimes /></button>
                                        </div>

                                        <div className="popup-content">
                                            <div className="tabHeader">
                                                <div
                                                    className={`tabButton ${selectedTab === "files" ? "active" : ""}`}
                                                    onClick={() => setSelectedTab("files")}
                                                >
                                                    Files
                                                </div>

                                                <div
                                                    className={`tabButton ${selectedTab === "website" ? "active" : ""}`}
                                                    onClick={() => setSelectedTab("website")}

                                                >
                                                    Website
                                                </div>
                                                {/* <div
                                                    className={`tabButton ${selectedTab === "qna" ? "active" : ""}`}
                                                    onClick={() => setSelectedTab("qna")}

                                                >
                                                    FAQ
                                                </div> */}

                                                <div
                                                    className={`tabButton ${selectedTab === "ds" ? "active" : ""}`}
                                                    onClick={() => setSelectedTab("ds")}

                                                >
                                                    Database
                                                </div>

                                                <div
                                                    className={`tabButton ${selectedTab === "prompt" ? "active" : ""}`}
                                                    onClick={() => setSelectedTab("prompt")}
                                                >
                                                    Prompt
                                                </div>
                                                <div
                                                    className={`tabButton ${selectedTab === "github" ? "active" : ""}`}
                                                    onClick={() => setSelectedTab("github")}
                                                >
                                                    Github
                                                </div>

                                            </div>
                                            <div className="tabContent">
                                                <div className="topContent">
                                                    {selectedTab === "files" && (
                                                        <div className="commonDiv files-content">
                                                            {selectedTemplate.files?.length === 0 ? (
                                                                <div className="content">
                                                                    <center>There are no records to display</center>
                                                                </div>
                                                            ) : (
                                                                <div className="content">
                                                                    <div className="row">
                                                                        {selectedTemplate && selectedTemplate.files?.map((item: any) => (
                                                                            <div className="col-12 col-md-6 repeat mb-3">
                                                                                <div className="box" >
                                                                                    <p title={item.name}>{item.name}</p>
                                                                                    <a href={`${REDIRECT_URL}/${item.fileName}`} download={item.name} title="Download">
                                                                                        {/* <LuDownload /> */}  {/* need to add */}
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}

                                                    {selectedTab === "website" && selectedTemplate.website && (
                                                        <div className="commonDiv websiteContent">
                                                            {selectedTemplate.website.length === 0 ? (
                                                                <div className="content">
                                                                    <center>There are no records to display</center>
                                                                </div>
                                                            ) : (
                                                                <div className="content">
                                                                    <div className="row">
                                                                        {selectedTemplate.website.map((item: any, index: number) => (
                                                                            <div className="col-12 col-md-6 repeat mb-3" key={index}>
                                                                                <a className="box">
                                                                                    <p>{item.url}</p>
                                                                                </a>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}

                                                    {/* {selectedTab === "qna" && selectedTemplate.qAndAList && (
                                                        <div className="commonDiv qnaContent">
                                                            {selectedTemplate.qAndAList.length === 0 ? (
                                                                <div className="content accordionContainer">
                                                                    <center>There are no records to display</center>
                                                                </div>
                                                            ) : (
                                                                <div className="content accordionContainer" >
                                                                    {selectedTemplate.qAndAList.map((section: any, index: any) => (
                                                                        <div className="repeatDiv" key={index}>
                                                                            <div
                                                                                className="accordion-header"
                                                                                onClick={() => toggleSection(index)}
                                                                                style={{ cursor: 'pointer', marginBottom: '5px' }}
                                                                            >
                                                                                <strong>{section.question}</strong>
                                                                                <div className="accordionTrgr" >
                                                                                    <LuChevronDown />
                                                                                </div>
                                                                            </div>
                                                                            {openSection === index && (
                                                                                <div className="accordion-content">{section.answer}</div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}

                                                        </div>
                                                    )} */}


                                                    {selectedTab === "ds" && selectedTemplate.databaseConnections && (
                                                        <div className="commonDiv">
                                                            {selectedTemplate.databaseConnections.length === 0 ? (
                                                                <div className="content">
                                                                    <center>There are no records to display</center>
                                                                </div>
                                                            ) : (
                                                                <div className="content">
                                                                    <DataTable
                                                                        noHeader={true}
                                                                        columns={tableColumns}
                                                                        data={selectedTemplate.databaseConnections}
                                                                        // pagination
                                                                        // paginationServer
                                                                        pagination={true}
                                                                        paginationPerPage={10}
                                                                        paginationRowsPerPageOptions={[10, 15, 20]}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}

                                                    {selectedTab === "prompt" && selectedTemplate.prompts && (
                                                        <div className="commonDiv promtSelection">
                                                            {selectedTemplate.prompts.length === 0 ? (
                                                                <div className="content">
                                                                    <center>There are no records to display</center>
                                                                </div>
                                                            ) : (
                                                                <div className="content">
                                                                    <div className="row">
                                                                        {selectedTemplate.prompts.map((prompt: any) => (
                                                                            <div className="col-12 col-md-6 col-lg-6 mb-3 boxHolder">
                                                                                <div className="box">
                                                                                    <div className="d-flex gap-3 title-bar align-items-center">
                                                                                        <div className="iconHolder">
                                                                                            <LuFileText />
                                                                                        </div>
                                                                                        <label className="form-check-label">
                                                                                            {prompt.name}
                                                                                        </label>

                                                                                    </div>
                                                                                    <p className="description">
                                                                                        {prompt.description}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}

                                                        </div>
                                                    )}
                                                    {selectedTab === "github" && (
                                                        <div className="commonDiv">
                                                            <div className="dataTable">
                                                                {selectedTemplate?.github && (
                                                                    <DataTable
                                                                        noHeader={true}
                                                                        columns={GHTableColumn}
                                                                        data={selectedTemplate?.github ? selectedTemplate?.github : []}
                                                                        // pagination
                                                                        // paginationServer
                                                                        pagination={true}
                                                                        paginationPerPage={10}
                                                                        paginationRowsPerPageOptions={[10, 15, 20]}
                                                                    />
                                                                )}
                                                            </div>


                                                        </div>
                                                    )}


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {templateList?.length == 0 && <>
                            <div className="msg right-msg">
                                <div className="leftItems">
                                    <div className="chatDesc">
                                        <center>There are no records to display</center>
                                    </div>
                                </div>
                            </div>

                        </>}
                    </div>
                    {totalCount > pageSize &&
                        <ReactPaginate
                            className="pagination"
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(totalCount / pageSize)}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                    }
                </div>
            </div>
            <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
        </>
    );
};

export default TemplateList;
