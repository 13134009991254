import React, { useEffect, useRef, useState } from "react";

import ChatBot from "./ChatBot/ChatBot";
import ChatLog from "./ChatLog/ChatLog";
import Sources from "./Sources/Sources";
import { useSearchParams } from 'react-router-dom';
import IAgentService from "../../Services/AgentServices/IAgentService";
import AgentService from "../../Services/AgentServices/AgentService";
import ITHEME from "../../Services/ThemeServices/IThemeServices";
import ApiKey from "./APIKey/ApiKey";
import EmbedOnSite from "./EmbedOnSite/EmbedOnSite";
import Prompt from "./Prompt/Prompt";
import BasicDetails from "./BaiscDetails/BasicDetails";
import { useNavigate } from "react-router-dom";
import { ThreeDotLoader } from "../Loader/Loader";
import Integrations from "./Integrations/Integrations";
import './AgentDetails.scss'
import { useDispatch, useSelector } from "react-redux";
import ChatOption from "./Preferences/ChatOption/ChatOption";
import Chat from "./Chat/Chat";
import { ASSISTANT_INITIAL_OBJ } from "../../Constants/constants";
import DashboardService from "../../Services/DashboardServices/DashboardService";
import { saveCompanyName } from "../../Stores/CompanyName/ComapanyAction";
import ICompanyInfo from "../../Services/DashboardServices/IDashboardService";
import Preferences from "./Preferences/Preferences";
import { deleteTemplateData } from "../../Stores/TemplateStore/TemplateAction";

const AgentDetails = ({ Props }: any) => {
    const navigate = useNavigate();
    // const templateId = useSelector((state: any) => state.templateReducer)

    const [searchParams] = useSearchParams();
    const assId = searchParams.get('assistantId')
    const selectedAssistantId = useRef(assId ? assId : '');

    const [selectedTheme, setSelectedTheme] = useState<ITHEME>({})
    const [agent, setAgent] = useState<IAgentService>(ASSISTANT_INITIAL_OBJ)

    const [chatData, setChatData] = useState<Array<object>>([{}])
    const [IsDefaultQuestion, setIsDefaultQuestion] = useState<boolean>(true)
    const [showLoader, setshowLoader] = useState(false);
    const [themes, setThemes] = useState<ITHEME[]>([])
    const [templateData, setTemplateData] = useState<any[]>([]);
    const [selectedMainTab, setSelectedMainTab] = useState('basicdetails');
    const dispatch = useDispatch()
    const templateId = useSelector((state: any) => state.templateReducer)

    useEffect(() => {
        if (selectedAssistantId.current) {
            getAssistantById()
            if (localStorage.getItem("assistantCreated")) {
                setSelectedMainTab("sources")
                localStorage.removeItem("assistantCreated")
            }
        }
        getComapanyInfo()

    }, [])

    const getAssistantById = () => {
        setshowLoader(true)
        AgentService.GetAssistantById(selectedAssistantId.current).then(res => {
            let data = res.response
            setAgent(data)
            setThemes(data?.theme || [])
            let theme = data?.theme?.filter((item: ITHEME, index: number) => item.isSelected == true)
            setSelectedTheme(theme?.length > 0 ? theme[0] : {})
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setshowLoader(false)
        })
    }
    const getComapanyInfo = () => {
        setshowLoader(true)
        DashboardService.GetCompanyInfo().then(res => {
            let data: ICompanyInfo = res.response
            dispatch(saveCompanyName(data.name ? data.name : ""))
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setshowLoader(false)
        })
    }


    return (<>
        <div className="contentWrapper agentDetails">
            <div className="row">
                <div className="col-lg-2 col-md-12 tabMenu">

                    {/* <div
                        className={`tabButton ${selectedMainTab === "templates" ? "active" : ""}`}
                        onClick={selectedAssistantId.current === "" ? (() => setSelectedMainTab("templates")) as any : ()=>undefined}
                        
                    >
                        Templates
                    </div> */}
                    <div
                        className={`tabButton ${selectedMainTab === "basicdetails" ? "active" : ""}`}
                        onClick={() => setSelectedMainTab("basicdetails")}
                    >
                        Basic Details
                    </div>


                    <div
                        className={`tabButton ${selectedMainTab === "sources" ? "active" : ""}`}
                        // onClick={() => setSelectedMainTab("sources")}
                        onClick={selectedAssistantId.current !== "" ? (() => setSelectedMainTab("sources")) as any : () => undefined}

                    >
                        Sources
                    </div>
                    <div
                        className={`tabButton ${selectedMainTab === "prompt" ? "active" : ""}`}
                        // onClick={() => setSelectedMainTab("prompt")}
                        onClick={selectedAssistantId.current !== "" ? (() =>
                            setSelectedMainTab("prompt")) as any : () => undefined}
                    >
                        Prompts
                    </div>

                    <div
                        className={`tabButton ${selectedMainTab === "chatbot" ? "active" : ""}`}
                        // onClick={() => setSelectedMainTab("chatbot")}
                        onClick={selectedAssistantId.current !== "" ? (() => setSelectedMainTab("chatbot")) as any : () => undefined}


                    >
                        Agent Preview & Theme
                    </div>
                    <div
                        className={`tabButton ${selectedMainTab === "preferences" ? "active" : ""}`}
                        // onClick={() => setSelectedMainTab("chatOption")}
                        onClick={selectedAssistantId.current !== "" ? (() => setSelectedMainTab("preferences")) as any : () => undefined}


                    >
                        Preferences
                    </div>
                    <div
                        className={`tabButton ${selectedMainTab === "chatLog" ? "active" : ""}`}
                        // onClick={() => setSelectedMainTab("chatLog")}
                        onClick={selectedAssistantId.current !== "" ? (() => setSelectedMainTab("chatLog")) as any : () => undefined}


                    >
                        Chat Log
                    </div>
                    <div
                        className={`tabButton ${selectedMainTab === "chat" ? "active" : ""}`}
                        // onClick={() => setSelectedMainTab("chat")}
                        onClick={selectedAssistantId.current !== "" ? (() => setSelectedMainTab("chat")) as any : () => undefined}


                    >
                        Preview 1
                    </div>
                    <div
                        className={`tabButton ${selectedMainTab === "integrations" ? "active" : ""}`}
                        // onClick={() => setSelectedMainTab("integrations")}
                        onClick={selectedAssistantId.current !== "" ? (() => setSelectedMainTab("integrations")) as any : () => undefined}


                    >
                        Integrations
                    </div>

                    <div
                        className={`tabButton ${selectedMainTab === "apiKeys" ? "active" : ""}`}
                        // onClick={() => setSelectedMainTab("apiKeys")}
                        onClick={selectedAssistantId.current !== "" ? (() => setSelectedMainTab("apiKeys")) as any : () => undefined}

                    >
                        API Keys
                    </div>



                </div>
                <div className="col-lg-10 col-md-12 tabContent tab-content-holder">

                    {/* {selectedMainTab === "templates" && (
                        <div className="commonDiv" >
                            <Templates
                                SetSelectedMainTab={setSelectedMainTab}
                            />
                        </div>
                    )} */}
                    {selectedMainTab === "basicdetails" && (
                        <div className="commonDiv">
                            <BasicDetails
                                SetSelectedMainTab={setSelectedMainTab} />
                        </div>
                    )}

                    {selectedMainTab === "sources" && (
                        <div className="commonDiv">
                            <Sources
                                SetSelectedMainTab={setSelectedMainTab}
                                SelectedMainTab={selectedMainTab}
                            />
                        </div>
                    )}

                    {selectedMainTab === "prompt" && (
                        <div className="commonDiv">
                            <Prompt
                                AgentInfo={agent}
                                TemplateInfo={templateData ? templateData : []}
                                SetSelectedMainTab={setSelectedMainTab}
                                SelectedMainTab={selectedMainTab}
                            />
                        </div>
                    )}

                    {selectedMainTab === "chatbot" && (
                        <div className="commonDiv">
                            <ChatBot
                                AssistantId={selectedAssistantId.current ? selectedAssistantId.current : ''}
                                ChatData={chatData}
                                SetChatData={setChatData}
                                SetSelectedMainTab={setSelectedMainTab}
                                SelectedMainTab={selectedMainTab}
                            />
                        </div>
                    )}
                    {selectedMainTab === "chat" && (
                        <div className="commonDiv">
                            <Chat
                                AssistantId={selectedAssistantId.current ? selectedAssistantId.current : ''}
                                ChatData={chatData}
                                SetChatData={setChatData}
                                SelectedTheme={selectedTheme}
                            />
                        </div>
                    )}

                    {selectedMainTab === "preferences" && (
                        <div className="commonDiv">
                            <Preferences
                                SetSelectedMainTab={setSelectedMainTab}
                            />
                        </div>
                    )}
                    {selectedMainTab === "chatLog" && (
                        <div className="commonDiv">
                            <ChatLog AssistantId={selectedAssistantId.current ? selectedAssistantId.current : ''} />
                        </div>
                    )}

                    {selectedMainTab === "integrations" && (
                        <div className="commonDiv">
                            <Integrations />
                        </div>
                    )}
                    {selectedMainTab === "apiKeys" && (
                        <div className="commonDiv">
                            <ApiKey
                            // AgentInfo={selectedAssistantId.current ? selectedAssistantId.current : ''}

                            />
                        </div>
                    )}

                    {selectedMainTab === "embedOnSite" && (
                        <div className="commonDiv">
                            <div className="titleBar">
                                {/* <h3>Embed on site</h3> */}
                                <EmbedOnSite agentId={selectedAssistantId.current}
                                />
                            </div>

                        </div>
                    )}

                </div>

            </div>

        </div >
        <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>)
}
export default AgentDetails