import React, { useState, useEffect } from "react";
import { toastError, toastSuccess } from "../Toast/Toast";
import { ThreeDotLoader } from "../Loader/Loader";
import { LuCheck, LuTrash, LuFileText } from "react-icons/lu";
import { FaCircleCheck } from "react-icons/fa6";
import { usePapaParse } from "react-papaparse";
import { confirmAlert } from "react-confirm-alert";
import "./Template.scss";

import IGroupService from "../../Services/GroupService/IGroupService";
// import DatabaseService from "../../Services/Database/DatabaseService";
import PromptService from "../../Services/PromptService/PromptService";
import GroupService from "../../Services/GroupService/GroupService";
import TemplateService from "../../Services/TemplateService/TemplateService";
import { useNavigate } from "react-router-dom";
import ITemplateService, { } from "../../Services/TemplateService/ITemplateService";
import IPromptService from "../../Services/PromptService/IPromptService";
import GitHub from "../GItHub/GitHub";
import UploadFiles from "../Files/HandleFIles";
import Summary from "../Summary/Summary";
import Database from "../Database/Database";
import Youtube from "../Youtube/Youtube";
import Slack from "../Slack/Slack";
import { ISAgentFile } from "../Utils/Utils";
import Website from "../Website/Website";
import { tab } from "@testing-library/user-event/dist/tab";
import { saveTemplateData } from "../../Stores/TemplateStore/TemplateAction";
import { useDispatch } from "react-redux";




const CreateTemplate = () => {

	const [selectedTab, setSelectedTab] = useState("basicdetails");
	const [groupList, setGroupList] = useState<IGroupService[]>([]);
	const [databaseConnectionsList, setDatabaseConnectionsList] = useState<any[]>([]);
	const navigate = useNavigate();

	const [createTemplateData, setCreateTemplateData] = useState<ITemplateService>({
		id: "",
		name: "",
		group: "",
		instruction: "",
		databaseConnections: [],
		prompts: [],
		files: [],
		website: [],
		qAndAList: [],
		github: []
	});

	//Sources
	const [selectedSourceTab, setSelectedSourceTab] = useState("files");
	const [showLoader, setShowLoader] = useState(false);
	const [isItemDisabledQA, setIsItemDisabledQA] = useState<boolean[]>([]);

	const dispatch = useDispatch()
	const [selectedURL, setSelectedURL] = useState("");
	const [deletedFileIds, setDeletedFileIds] = useState<Array<string>>([])
	const [filesCount, setFilesCount] = useState(0);
	const [qaCount, setQACount] = useState(0);
	const [websiteCount, setWebsiteCount] = useState(0);

	const disableSubmitButton = React.useRef<boolean>(true)
	const templateId = React.useRef<string>("")

	const [promptsList, setPromptsList] = useState<IPromptService[]>([]);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);


	useEffect(() => {
		getAllGroups();
		if (createTemplateData.qAndAList) {
			setIsItemDisabledQA(Array(createTemplateData.qAndAList.length).fill(false));
		}
	}, [createTemplateData.qAndAList]);


	useEffect(() => {
		if (selectedTab === "sources" && templateId.current) {
			getTemplateById(templateId.current)
		}
	}, [selectedTab]);


	useEffect(() => {
		setFilesCount(createTemplateData.files ? createTemplateData.files.length : 0);
		setQACount(createTemplateData.qAndAList ? createTemplateData.qAndAList.length : 0)
		setWebsiteCount(createTemplateData.website ? createTemplateData.website.length : 0)
	}, [createTemplateData.qAndAList, createTemplateData.files, createTemplateData.website]);


	const getAllGroups = () => {
		GroupService.GetGroupList().then((res) => {
			setGroupList(res.statusCode == 200 ? res.response : []);
		});
	};

	const updateTemplateForm = (target: any) => {
		let name = target.name;
		let val = target.value;
		if (name === "GroupId") {
			val = Number(val)
		}
		setCreateTemplateData({
			...createTemplateData,
			[name]: val,
		});
	};



	//////////////////prompts//////////////

	useEffect(() => {
		getAllPrompts();

	}, []);


	const getAllPrompts = () => {
		PromptService.GetAllPrompts().then((res) => {
			if (res) {
				if (Array.isArray(res.response)) {
					setPromptsList(res.response);
				} else {
					console.error("Invalid response format from GetAllPrompts API");
				}
			}
		});
	};


	const updatePromptForm = (prompt: IPromptService, checked: boolean) => {
		setCreateTemplateData((preVal) => {
			let updatedPromptList
			if (checked) {
				updatedPromptList = [...(preVal.prompts || []), prompt]

			} else {
				updatedPromptList = preVal.prompts?.filter((item: any) => item.name !== prompt.name)
			}
			return {
				...preVal,
				prompts: updatedPromptList ? updatedPromptList : []
			}
		});
	};

	/////////////////////

	const onChangeTab = (tab: string) => {
		setSelectedSourceTab(tab)
	}



	const validateTemplateData = () => {
		let flag = true
		if (!createTemplateData.name) {
			toastError("Please Enter Template Name")
			flag = false
		}
		if (!createTemplateData.group) {
			toastError("Please Select Group")
			flag = false
		}

		if (!createTemplateData.instruction) {
			toastError("Please Enter Instruction")
			flag = false
		}
		if (createTemplateData.files && (createTemplateData.files.length > 20)) {
			toastError("Please select files up to 20.");
			flag = false
		}

		const websiteUrls = createTemplateData.website?.map((website: any) => website);
		if (websiteUrls) {
			const uniqueUrls = new Set(websiteUrls);
			if (websiteUrls.length !== uniqueUrls.size) {
				toastError("Duplicate website URLs are not allowed.");
				return false;
			}
		}

		const fileNames = createTemplateData.files?.map((file: any) => file.name && file.name.toLowerCase());
		const uniqueFileNames = new Set(fileNames);
		if (fileNames && fileNames.length !== uniqueFileNames.size) {
			toastError("Duplicate file names are not allowed.");
			return false;
		}


		const maxSize = 25 * 1048576;
		const totalSize = createTemplateData.files?.reduce((acc: any, file: any) =>
			acc + (file instanceof File ? file.size : 0), 0);
		if (totalSize && totalSize > maxSize) {
			toastError(`Total file size should be less than 25 MB.`);
			return false;
		}

		const questions = createTemplateData.qAndAList?.map((item: any) => item.question);
		if (questions) {
			const uniqueQuestions = new Set(questions);
			if (questions.length !== uniqueQuestions.size) {
				toastError("Duplicate questions are not allowed.");
				return false;
			}
		}

		let exten: any[] = []
		createTemplateData.files?.map((file: any) => {
			if (!ISAgentFile(file)) {
				var ext = file.name.substr(file.name.lastIndexOf('.') + 1);
				exten.push(ext)
			}
		})

		const counts = exten.reduce((acc, name) => {
			acc[name] = (acc[name] || 0) + 1;
			return acc;
		}, {});

		if (counts['csv'] > 1 || counts['xlsx'] > 1 || counts['xls'] > 1) {
			toastError("Please upload only one .xlsx,.xls or .csv file at a time");
			return false;

		}


		return flag
	}

	const previousTab = (tab: any) => {
		if (tab === "prompt") {
			setSelectedTab("sources")
		}
		if (tab === "sources") {
			setSelectedTab("basicdetails")
		}
	}

	const basicdetailsValidation = () => {

		let flag = true
		if (!createTemplateData.name) {
			toastError("Please Enter Template Name")
			flag = false
		}
		if (!createTemplateData.group) {
			toastError("Please Select Group")
			flag = false
		}

		if (!createTemplateData.instruction) {
			toastError("Please Enter Instruction")
			flag = false
		}
		if (flag) {
			setSelectedTab("sources")
		}
		return flag

	}


	const createTemplateDetails = () => {
		if (basicdetailsValidation()) {
			setIsDisabled(true)
			TemplateService.CreateTemplate(createTemplateData).then((res) => {
					toastSuccess("Template Created Successfully")
					// dispatch(saveTemplateData(res.response))
					// localStorage.setItem("temId", res)
					templateId.current = res.response
					getTemplateById(res)
					// navigate("/Templates")
					setSelectedTab("sources")

				})
				.catch((err) => {
					setSelectedTab("basicdetails")
					toastError(err?.response?.data?.response)
				}).finally(() => {
					setShowLoader(false);
					setIsDisabled(false)
				});
		}
	}


	const getTemplateById = (id:string) => {
		setShowLoader(true);
		TemplateService.GetTemplateById(templateId.current?templateId.current:id).then(res => {
			setCreateTemplateData({ ...res.response })
		}).catch(err => {
			toastError(err?.response?.data?.response)
		}).finally(() => {
			setShowLoader(false)
		})
	}

	const updateTemplateDetails = (tab: any) => {
		setShowLoader(true);
		setIsDisabled(true)
		TemplateService.UpdateTemplate(createTemplateData, templateId.current ? templateId.current : "")
			.then((res) => {
				toastSuccess(res.response)
				getTemplateById(templateId.current)
				// navigate("/Templates")
				// checkRedirectPermission()
				if (tab === "basicdetails") {
					setSelectedTab("sources")
				} else if (tab === "sources") {
					setSelectedTab("prompt")

				} else if (tab === "prompt") {
					navigate("/Templates");

				}


			})
			.catch((err) => {
				toastError(err?.response?.data?.response)
			}).finally(() => {
				setShowLoader(false);
				setIsDisabled(false)
			});



	}


	function getTotalLength(inputVal: any) {
		if (inputVal === null) {
			return 0;
		}
		return inputVal.length + (inputVal.match(/\n/g) || []).length;
	}


	const canceleForm = () => {
		// navigate("/Templates")
		checkRedirectPermission()
	};


	const checkRedirectPermission = () => {
		navigate("/Templates");
	}


	return (
		<div className="contentWrapper create-template">
			<div className="row">
				<div className="col-12 col-md-2 tabMenu">
					<div
						className={`tabButton ${selectedTab === "basicdetails" ? "active" : ""}`}
						onClick={() => setSelectedTab("basicdetails")}>
						Basic Details
					</div>
					<div
						className={`tabButton ${selectedTab === "sources" ? "active" : ""}`}
						onClick={() => setSelectedTab("sources")}>
						Sources
					</div>
					<div
						className={`tabButton ${selectedTab === "prompt" ? "active" : ""}`}
						onClick={() => setSelectedTab("prompt")}>
						Prompt
					</div>
				</div>


				<div className="col-12 col-md-10 tabContent">
					{selectedTab === "basicdetails" && (
						<div className="commonDiv promtSelection">
							<div className="row">
								<div className="col-12 col-md-4 formHolder nameHolder mb-5">
									<label className="form-label">Template Name <span className="danger">*</span></label>
									<input
										type="text"
										placeholder="Enter the name"
										name="name"
										className="form-control"
										readOnly={templateId.current ? true : false}
										onChange={(event) => updateTemplateForm(event.target)}
										value={createTemplateData.name}
									/>
								</div>
								<div className="col-12 col-md-4 formHolder selectHolder mb-5">
									<label className="form-label">Group<span className="danger">*</span></label>
									<select
										value={createTemplateData.group}
										name="group"
										disabled={templateId.current ? true : false}
										onChange={(event) => updateTemplateForm(event.target)}
										className="form-select">
										<option value="">Select a Group</option>
										{groupList && groupList.map((group) => (
											<option key={group.name} value={group.name}>{group.name}</option>
										))}
									</select>
								</div>

								<div className="col-12 col-md-8 formHolder descHolder mb-5">
									<label className="form-label">Instruction <span className="danger">*</span></label>
									<textarea className="form-control"
										placeholder="Enter the Instruction"
										name="instruction"
										onChange={(event) => updateTemplateForm(event.target)}
										value={createTemplateData.instruction}
										maxLength={500}
										readOnly={templateId.current ? true : false}
									/>
									{getTotalLength(createTemplateData.instruction) > 499 && (<div className="danger">Instructions cannot exceed 500 characters.</div>)}
								</div>

							</div>

							<div className="col-12 col-md-8 mt-2 btnHolder d-flex gap-3">
								<button type="button" className="btn btn-secondary ms-auto" onClick={() => canceleForm()}>Cancel</button>
								<button className="btn btn-primary" type="button" onClick={() => !templateId.current ? createTemplateDetails() : updateTemplateDetails("basicdetails")}>Next</button>
							</div>

						</div>
					)}
					{selectedTab === "sources" && (
						<div className="commonDiv">

							<div className="sourceHolder ">
								<div className="tabHeader">
									<div
										className={`tabButton ${selectedSourceTab === "files" ? "active" : ""}`}
										onClick={() => onChangeTab("files")}
									>
										Files
									</div>
									<div
										className={`tabButton ${selectedSourceTab === "website" ? "active" : ""}`}
										onClick={() => onChangeTab("website")}
									>
										Website
									</div>
									{/* <div
										className={`tabButton ${selectedSourceTab === "qna" ? "active" : ""}`}
										onClick={() => onChangeTab("qna")}
									>
										FAQ
									</div> */}

									<div
										className={`tabButton ${selectedSourceTab === "ds" ? "active" : ""}`}
										onClick={() => onChangeTab("ds")}
									>
										Database
									</div>
									<div
										className={`tabButton ${selectedSourceTab === "github" ? "active" : ""}`}
										onClick={() => onChangeTab("github")}
									>
										GitHub
									</div>
									<div
										className={`tabButton ${selectedSourceTab === "youtube" ? "active" : ""}`}
										onClick={() => onChangeTab("youtube")}
									>
										Youtube
									</div>
									<div
										className={`tabButton ${selectedSourceTab === "slack" ? "active" : ""}`}
										onClick={() => onChangeTab("slack")}
									>
										Slack
									</div>
									<div
										className={`summary-tab tabButton ${selectedSourceTab === "summary" ? "active" : ""}`}
										onClick={() => onChangeTab("summary")}
									>
										Summary
									</div>
								</div>

								<div className="tabContent">
									<div className="topContent">
										{selectedSourceTab === "files" && (
											<UploadFiles UpdateAssistant={createTemplateData}
												SetUpdateAssistant={setCreateTemplateData}
												SetDeletedFileIds={setDeletedFileIds}
												DisableSubmitButton={disableSubmitButton}
												TemplateId={templateId.current} />
										)}
										{selectedSourceTab === "website" && (
											<Website UpdateAssistant={createTemplateData}
												SetUpdateAssistant={setCreateTemplateData}
												DisableSubmitButton={disableSubmitButton}
												TemplateId={templateId.current} />
										)}
										{/* {selectedSourceTab === "qna" && (
											
										)} */}
										{selectedSourceTab === "ds" && (
											<Database SetUpdateAssistant={setCreateTemplateData}
												UpdateAssistant={createTemplateData}
												DisableSubmitButton={disableSubmitButton} />
										)}
										{selectedSourceTab === "github" && (
											<GitHub SetAssistantData={setCreateTemplateData}
												AssistantData={createTemplateData}
												DisableSubmitButton={disableSubmitButton} />
										)}
										{selectedSourceTab === "summary" && (
											<Summary Assistantdata={createTemplateData} />
										)}
										{selectedSourceTab === "youtube" && (
											<Youtube />
										)}
										{selectedSourceTab === "slack" && (
											<Slack />
										)}
									</div>

								</div>


								<div className="w-100 mt-4 btnHolder d-flex gap-3">
									<button type="button" className="btn btn-secondary ms-auto" onClick={() => previousTab("sources")}>Previous</button>
									<button className="btn btn-primary" type="button" onClick={() => updateTemplateDetails("sources")}>Next</button>
								</div>
							</div>

						</div>
					)}

					{selectedTab === "prompt" && (
						<div className="commonDiv promtSelection">
							<div className="row">

								{promptsList && promptsList.map((prompt) => (
									<div className="col-12 col-md-4 col-lg-4 mb-4 boxHolder" key={prompt.id}>
										<div className="box justify-content-between">
											<div className="d-flex gap-3 title-bar align-items-center">
												<div className="iconHolder">
													<LuFileText />
												</div>
												<label className="form-check-label" htmlFor={prompt.name}>
													{prompt.name}
												</label>

											</div>
											<p className="description">{prompt.description}</p>
											<div className="chck-holder">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id={prompt.name}
													checked={createTemplateData.prompts?.map(item => item.name).includes(prompt.name ? prompt.name : '')}
													onChange={(e) => updatePromptForm(prompt, e.target.checked)}
												/>
												<div className="checkDesignHolder" >
													<span>{createTemplateData.prompts?.map(item => item.name).includes(prompt.name ? prompt.name : '') ? <LuCheck /> : null}</span></div>
											</div>
										</div>
									</div>
								))}

								{promptsList?.length == 0 && <>
									<div className="msg right-msg">
										<div className="leftItems">
											<div className="chatDesc">
												<center>There are no records to display</center>
											</div>
										</div>
									</div>

								</>}

								<div className="col-12 col-md-12 mt-2 btnHolder d-flex gap-3">
									<button type="button" className="btn btn-secondary ms-auto" onClick={() => previousTab("prompt")}>Previous</button>
									<button className="btn btn-primary" type="button" disabled={isDisabled} onClick={() => updateTemplateDetails("prompt")} >Create</button>
								</div>
							</div>

						</div>
					)}


				</div>

			</div>

			<ThreeDotLoader visibility={showLoader}></ThreeDotLoader>

		</div >
	);
};

export default CreateTemplate;
