import { IAgentFile } from "../../Services/TemplateService/ITemplateService";
import { v4 as uuidv4 } from 'uuid';

export function ISAgentFile(fileItem: any): fileItem is IAgentFile {
    return 'trainingStatus' in fileItem
    // if (typeof fileItem === 'string' || fileItem instanceof String) {
    //     return true
    // } else {
    //     return false
    // }
}

export function getFileName(fileItem: File | IAgentFile) {
    let name
    // if (ISFile(fileItem)) {
    //     name = fileItem.name
    // } else {
    //     name = fileItem.name ? fileItem.name : ''
    // }
    name = fileItem.name ? fileItem.name : ""
    var extn = name.split(".")[1];
    var filename;
    if (name.split(".")[0].trim().length > 10) {
        filename = name.length > 10 ? name.substring(0, 10) + "... ." + extn : name;

    } else {
        filename = name;
    }
    return { filename, fullfilename: name };
};
export function getFileSize(fileItem: any | string) {
    let size
    if (ISAgentFile(fileItem)) {
        size = fileItem.fileSize
    } else {
        size = fileItem.size ? fileItem.size : 0
        size = (size / (1024 * 1024)).toFixed(2)
        size = `${size} MB`
    }
    return size
};
export function getTrainingStatus(fileItem: any | string) {
    // New 0 1, Training 2, trained 3, Failed 4
    let statusObj = { New: 1, Training: 2, Trained: 3, Falied: 4 }
    let status
    if (ISAgentFile(fileItem) && fileItem.trainingStatus) {
        status = statusObj[fileItem.trainingStatus.trim() as keyof typeof statusObj]
    } else {
        status = 0
    }
    return status
};

export function createFileChuncks(file: File, chunckSize?: number) {
    const chunckSizes = chunckSize ? chunckSize : 5 * 1024 * 1024
    const chuncks = []
    var start = 0
    while(start < file.size){
        const end = start + chunckSizes;
        chuncks.push(file.slice(start, end));
        start = end;
    }
    return chuncks
}

export function createChunkFileForms(assistantId: string, files: File[]):Array<any> {
    const fileForms: any = []
    files.map(file => {
        let chunks = createFileChuncks(file)
        let totalNumberOfChunks = chunks.length
        let fileId = uuidv4()
        let fileExtension = file.type
        let fileName = file.name
        // for (let chunckNumber = 0; chunckNumber < totalNumberOfChuncks; chunckNumber++) {
        chunks.map((chunk: any, index: number) => {
            let formData = new FormData()
            formData.append("file", chunk)
            formData.append("chunkNumber", String(index + 1))
            formData.append("totalChunks", String(totalNumberOfChunks))
            formData.append("fileId", fileId)
            formData.append("fileName", fileName)
            fileForms.push(formData)
        })
    })
    return fileForms
}

export function formateDate(date: string): string {
    let strDate = ''
    let newDate = new Date(date)
    strDate = strDate + `${newDate.toLocaleDateString()} - ${newDate.toLocaleTimeString()}`
    return strDate
}
