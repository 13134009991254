
import { url } from "inspector";
import { CHAT_LOG, } from "../EndPoint";
import IResponse from "../IResponse";
import TokenService from "../TokenService/TokenService";
import instance from "../AxiosInstance";

export default class ChatLogServices {
    public static GetAllChatHistory(assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${CHAT_LOG}/${assistantId}/history`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static GetAllChatSessions(assistantId:string):Promise<IResponse>{
        return new Promise((resolve, reject)=>{
            instance.get(`${CHAT_LOG}/${assistantId}/session`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

}